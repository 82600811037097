import request from '@/tools/request';

export const getDefaultData = loading => {
  return request({
    url: '/termgenerator-config-edit.html',
    loading,
  });
};

export const saveConfig = (data, loading) => {
  return request({
    url: '/termgenerator-config-edit.html',
    method: 'post',
    loading,
    data,
  });
};
